<template>
<div>
    <v-col cols="12" sm="4">
        <h4>Agregar nueva Patologia</h4>
    </v-col>
    <v-form>

                <v-text-field v-model="patologia.nombre" clearable type="text" label="Nombre"></v-text-field>
                <v-btn color="primary" @click="savePatologia();  reset, resetValidation, newPatologia()">
                    Agregar
                </v-btn>
        <v-btn color="red" @click="goToList"> Volver</v-btn>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

    </v-form>

    <v-snackbar v-model="snackbar" :multi-line="multiLine">
        Patologia guardada correctamente a las {{showEditedTime()}}
        <template v-slot:action="{ attrs }">
            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>

</div>
</template>

<script>
import PatologiaDataService from "../services/PatologiaDataService";
import ConfirmDialogue from './ConfirmDialogue.vue';
export default {
    name: "add-patologia",
    components: {
        ConfirmDialogue
    },
    data() {
        return {
            
            dialog: false,
            snackbar: false,
            requiredRules: [
                v => !!v || 'Este campo es requerido'
            ],
            multiLine: true,
            patologia: {
                id: null,
                nombre: ""
                
            },
            submitted: false,

        };

    },
    methods: {
        async goToList() {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Volver al listado?',
                message: 'Todos los cambios se perderan.',
                okButton: 'Volver',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.$router.push("/patologias");
            } else {
                // Doing nothing now, can throw an alert() too
            }
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        showEditedTime() {
            var editedTime = new Date();
            editedTime = editedTime.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            return editedTime
        },
        // THIS IS FOR THE DROPDOW LIST
        change(val) {
            console.log('val', val)
            this.option = val
        },
        select() {
            console.log('doing ', this.option)
        },
        // THIS IS FOR THE DROPDOW LIST
        savePatologia() {
            var data = {
                nombre: this.patologia.nombre

            };
            PatologiaDataService.create(data)
                .then((response) => {
                    this.patologia.nombre= response.data.nombre;
                    this.submitted = true;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        newPatologia() {
            this.submitted = false;
            this.patologia = {};
        },
    },
};
</script>

<style>
.submit-form {
    max-width: 800px;
}

.styled-input label[for] {
    height: 30px;
    font-size: 12pt;
}

/* Hide up and down buttons on numeric type fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
